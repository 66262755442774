import { verify, getLoginMember, listAll } from '@/api/user'
const state = {
  username: '',
  id: '',
  address: [],
  userInfo: {}
}
const getters = {
  getUser(state) {
    if (state.id) return true
    else return false
  },
  getAddress(state) {
    return state.address
  }
}
const mutations = {
  SET_USERNAME: (state, username) => {
    state.username = username
  },
  SET_ID: (state, id) => {
    state.id = id
  },
  SET_ADDRESS: (state, address) => {
    state.address = address
  },
  SET_USERINFO: (state, data) => {
    state.userInfo = data
  },
  CLEAR_USER: (state) => {
    state.id = ''
  }
}
const actions = {
  async getUserInfo({ commit, dispatch, state }) {
    await verify()
    const { data } = await getLoginMember()
    dispatch('car/getCartListInfo', {}, { root: true })
    sessionStorage.setItem('id', data.id)
    dispatch('getAddress', data.id)
    commit('SET_ID', data.id)
    commit('SET_USERNAME', data.username)
    commit('SET_USERINFO', data)
  },
  // async getLoginMemberFun({ commit, dispatch, state }) {
  //   const { data } = await getLoginMember()
  //   sessionStorage.setItem('id', data.id)
  //   commit('SET_ID', data.id)
  //   commit('SET_USERNAME', data.username)
  // },
  getAddress({ commit }, ID) {
    listAll(ID).then(res => {
      commit('SET_ADDRESS', res.data)
    })
  },
  changeAddress({ commit }, data) {
    commit('SET_ADDRESS', data)
  },
  clearUserID({ commit }) {
    commit('CLEAR_USER')
  }
}

export default { state, getters, mutations, actions }
