const state = {
  loading: false
}
const getters = {
  isLoading(state) {
    return state.loading
  }
}
const mutations = {
  SHOWLOADING(state, load) {
    state.loading = load
  }
}
const actions = {
  setLoading({ commit }, data) {
    commit('SHOWLOADING', data)
  }
}

export default { state, getters, mutations, actions }
