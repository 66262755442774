import { createRouter, createWebHashHistory } from 'vue-router'
import Layout from '@/Layout'
import Cookies from 'js-cookie'
import store from '@/store'

export const constantRoutes = [
  {
    path: '/',
    component: Layout,
    redirect: '/home',
    meta: { title: '首页', isNeedLogin: false },
    children: [
      {
        path: '/home',
        name: 'Home',
        component: () => import('@/views/home'),
        meta: { title: '首页', isNeedLogin: false }
      }
    ]
  },
  {
    path: '/user',
    component: Layout,
    redirect: 'noredirect',
    meta: { title: '用户', isNeedLogin: true },
    children: [
      {
        path: '/user/userInfo',
        name: 'UserInfo',
        component: () => import('@/views/user/userInfo'),
        meta: { title: '账号中心', isNeedLogin: true }
      },
      {
        path: '/user/applyInvoice',
        name: 'ApplyInvoice',
        component: () => import('@/views/user/applyInvoice'),
        meta: { title: '申请开票', isNeedLogin: true }
      },
      {
        path: '/user/collection',
        name: 'Collection',
        component: () => import('@/views/user/collection'),
        meta: { title: '收藏', isNeedLogin: true }
      },
      {
        path: '/user/footprint',
        name: 'Footprint',
        component: () => import('@/views/user/footprint'),
        meta: { title: '足迹', isNeedLogin: true }
      }
    ]
  },
  {
    path: '/order',
    component: Layout,
    redirect: 'noredirect',
    meta: { title: '订单', isNeedLogin: true },
    children: [
      {
        path: '/order/orderDetails',
        name: 'OrderDetails',
        component: () => import('@/views/order/orderDetails'),
        meta: { title: '订单详情', isNeedLogin: true }
      },
      {
        path: '/order/orderSettle',
        name: 'OrderSettle',
        component: () => import('@/views/order/orderSettle'),
        meta: { title: '订单结算', isNeedLogin: true }
      },
      {
        path: '/order/orderPay',
        name: 'OrderPay',
        component: () => import('@/views/order/orderPay'),
        meta: { title: '订单支付', isNeedLogin: true }
      },
      {
        path: '/order/paySuccess',
        name: 'PaySuccess',
        component: () => import('@/views/order/paySuccess'),
        meta: { title: '支付成功', isNeedLogin: false }
      },
      {
        path: '/order/pay',
        name: 'Pay',
        component: () => import('@/views/order/pay'),
        meta: { title: '支付', isNeedLogin: false }
      }
    ]
  },
  {
    path: '/car',
    component: Layout,
    redirect: 'noredirect',
    meta: { title: '购物车', isNeedLogin: true },
    children: [
      {
        path: '/car',
        name: 'Car',
        component: () => import('@/views/car'),
        meta: { title: '购物车', isNeedLogin: true }
      }
    ]
  },
  {
    path: '/shop',
    component: Layout,
    redirect: '/shop/allgoods',
    meta: { title: '商品', isNeedLogin: false },
    children: [
      {
        path: '/shop/allgoods',
        name: 'AllGoods',
        component: () => import('@/views/shop/allGoods'),
        meta: { title: '全部商品', isNeedLogin: false }
      },
      {
        path: '/shop/details',
        name: 'Details',
        component: () => import('@/views/shop/details'),
        meta: { title: '商品详情', isNeedLogin: false }
      }
    ]
  },
  {
    path: '/login',
    component: () => import('@/views/login'),
    hidden: true
  },
  {
    path: '/login/forget',
    component: () => import('@/views/login/forget'),
    hidden: true
  },
  {
    path: '/:catch(.*)',
    component: () => import('@/views/404'),
    hidden: true
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes: constantRoutes
})
/**
 * needlogin
 * true   ->   you cookies   meiyou  cookies
 * false  ->   you cookies   meiyou  cookies
 */
router.beforeEach(async(to, from, next) => {
  if (to.meta.isNeedLogin && !Cookies.get('MALL_TOKEN')) {
    sessionStorage.setItem('MallToPath', to.path)
    if (JSON.stringify(to.query) !== '{}') {
      sessionStorage.setItem('MallToPathQuery', JSON.stringify(to.query))
    }
    next('/login')
  } else if (Cookies.get('MALL_TOKEN')) {
    sessionStorage.setItem('MallToPath', to.path)
    if (JSON.stringify(to.query) !== '{}') {
      sessionStorage.setItem('MallToPathQuery', JSON.stringify(to.query))
    }
    store.dispatch('user/getUserInfo')
    next()
  } else {
    if (to.path === '/shop/details') {
      sessionStorage.setItem('MallToPath', to.path)
      sessionStorage.setItem('MallToPathQuery', JSON.stringify(to.query))
    }
    next()
  }
})

router.afterEach((to) => {
  // NProgress.done()
})

export default router
