<template>
  <div>
    <db-header />
    <db-main />
    <db-footer />
  </div>
</template>

<script>
import DbHeader from '@/Layout/db-header'
import DbFooter from '@/Layout/db-footer'
import DbMain from '@/Layout/db-main'
import { mapActions } from 'vuex'
export default {
  name: 'Layout',
  components: { DbHeader, DbFooter, DbMain },
  data() {
    return {}
  },

  computed: {},
  created() {
    this.getUser()
  },

  methods: {
    ...mapActions({
      getUserInfo: 'user/getUserInfo'
    }),
    getUser() {
      if (sessionStorage.getItem('id') && this.$route.meta.isNeedLogin) {
        this.getUserInfo()
      }
    }
  }
}
</script>
<style lang='less' scoped>

</style>
