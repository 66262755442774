import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
import '@/styles/main.css'
import lazyPlugin from 'vue3-lazy'
import { message } from 'ant-design-vue'
message.config({
  top: '64px'
})
createApp(App).use(store).use(router).use(Antd).use(lazyPlugin, {
  // preLoad: 1.3,
  error: require('@/assets/logo.png'),
  loading: require('@/assets/logo.png')
  // attempt: 1
}).mount('#app')
