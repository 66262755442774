import { changeItemCount, checkAllItems, getCartList } from '@/api/car'
import { isCollect } from '@/api/goods'
const state = {
  shoppingCart: [],
  totalAmount: ''
}
const getters = {
  getIsAllCheck(state) {
    let isAllCheck = false
    const list = state.shoppingCart.filter(item => item.check === false)
    if (list.length) {
      isAllCheck = false
    } else {
      isAllCheck = true
    }
    return isAllCheck
  },
  getShoppingCart(state) {
    return state.shoppingCart
  },
  getTotalAmount(state) {
    state.totalAmount = state.shoppingCart.reduce((cur, item) => {
      cur += item.check && item.count * item.price
      return cur
    }, 0)
    return state.totalAmount
  }
}
const mutations = {
  SET_SHOPPINGCART: (state, data) => {
    state.shoppingCart = data
  },
  SET_CHECKALL: (state, data) => {
    state.shoppingCart = state.shoppingCart.map(item => {
      item.check = data
      return item
    })
  },
  SET_ISCOLLECT: (state, data) => {
    state.shoppingCart = state.shoppingCart.map(item => {
      if (item.skuId === data.skuId) {
        item.isCollect = data.type
      }
      return item
    })
  },
  ADD_SHOPPINGCART: (state, data) => {
    if (state.shoppingCart.length && state.shoppingCart.filter(item => item.skuId === data.skuId).length) {
      state.shoppingCart = state.shoppingCart.map(item => {
        if (item.skuId === data.skuId) {
          item.count = data.num
        }
        return item
      })
    } else {
      state.shoppingCart.push(data)
    }
  },
  SET_TOTALAMOUNT: (state, data) => {
    state.totalAmount = data
  },
  UPDATA_SHOPPINGCART: (state, data) => {
    state.shoppingCart = state.shoppingCart.map(item => {
      if (item.skuId === data.skuId) {
        item.count = data.num
        item.totalPrice = item.count * item.price
      }
      return item
    })
  },
  DEL_SHOPPINGCART: (state, data) => {
    state.shoppingCart = state.shoppingCart.filter(item => item.skuId !== data.skuId)
  },
  CLEAR_SHOPPINGCART: (state) => {
    state.shoppingCart = []
  }
}
const actions = {
  setShoppingCart({ commit, dispatch }, data) { // 设置购物车列表，金额
    commit('SET_SHOPPINGCART', data.items || [])
    commit('SET_TOTALAMOUNT', data.totalAmount)
    dispatch('isCollectFun', data)
    return true
  },
  getCartListInfo({ dispatch }) {
    getCartList().then(res => {
      dispatch('setShoppingCart', res.data)
    })
  },
  checkAll({ state, commit }, data) {
    const params = {
      skuIds: state.shoppingCart.map(item => item.skuId),
      check: data ? 1 : 0
    }
    checkAllItems(params).then(res => {
      if (res.code === 0) {
        commit('SET_CHECKALL', data)
      }
    })
  },
  addShoppingCart({ commit }, data) {
    commit('ADD_SHOPPINGCART', data)
  },
  changeItemCountFun({ commit }, data) {
    changeItemCount(data).then(res => {
      if (res.code === 0) {
        commit('UPDATA_SHOPPINGCART', data)
      }
    })
  },
  delShoppingCart({ commit }, data) {
    commit('DEL_SHOPPINGCART', data)
  },
  isCollectFun({ commit }, data) { // 判断商品是否被收藏
    if (data.items && data.items.length) {
      const obj = {
        skuId: ''
      }
      data.items.forEach(item => {
        obj.skuId = item.skuId
        isCollect(obj).then(res => {
          item.isCollect = res.data
          commit('SET_ISCOLLECT', { 'type': res.data, 'skuId': item.skuId })
        })
      })
    }
  },
  clearShopCart({ commit }) {
    commit('CLEAR_SHOPPINGCART')
  }
}

export default { state, getters, mutations, actions }
