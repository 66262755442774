import axios from 'axios'
import { message } from 'ant-design-vue'
import qs from 'qs'
import Cookies from 'js-cookie'
import router from '@/router'
import store from '../store'
// import { getToken } from '@/utils/auth'
// const baseurl = process.env.NODE_ENV === 'development' ? 'http://192.168.1.170:88' : 'http://39.107.88.135:88'
const baseurl = process.env.NODE_ENV === 'development' ? '' : 'https://api.ccpluss.com'
// 创建axios实例
axios.defaults.withCredentials = true // 携带cookie
// axios.withCredentials = true

const service = axios.create({
  baseURL: baseurl, // api的base_url
  timeout: 15000, // 请求超时时间
  headers: {
    'Content-Type': 'application/json;charset=UTF-8'
  }
})

// request拦截器
service.interceptors.request.use(config => {
  store.dispatch('loading/setLoading', true)
  const cookie = Cookies.get('MALL_TOKEN')
  // if (store.getters.token) {
  //   config.headers['Authorization'] = getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
  // }
  // console.log(cookie, 'cookie')
  // if (cookie) config.headers['Set-Cookie'] = cookie // 请求头带上token
  if (config.method === 'get' || config.method === 'post') {
    // 如果是get请求，且params是数组类型如arr=[1,2]，则转换成arr=1&arr=2
    config.paramsSerializer = function(params) {
      return qs.stringify(params, { arrayFormat: 'repeat' })
    }
  }
  if (config.url === '/api/auth/verify' && !cookie) {
    router.replace({ path: '/login' })
  }
  return config
}, error => {
  store.dispatch('loading/setLoading', false)
  // Do something with request error
  console.log(error, '_error_') // for debug
  Promise.reject(error)
})

// respone拦截器
service.interceptors.response.use(
  response => {
  /**
  * code为非200是抛错 可结合自己业务进行修改
  */
    const res = response.data
    if (response.config.url === '/api/order/web/payOrder') { // 直接返回
      store.dispatch('loading/setLoading', false)
      return response.data
    }
    if (res.code !== 0) {
      message.error(res.msg || res.message)

      // 401:未登录;
      if (res.code === 401) {
        message.confirm('你已被登出，可以取消继续留在该页面，或者重新登录', '确定登出', {
          confirmButtonText: '重新登录',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          // store.dispatch('FedLogOut').then(() => {
          //   location.reload()// 为了重新实例化vue-router对象 避免bug
          // })
        })
      }
      store.dispatch('loading/setLoading', false)
      return Promise.reject('error')
    } else {
      store.dispatch('loading/setLoading', false)
      return response.data
    }
  },
  error => {
    const { status, config } = error.response
    store.dispatch('loading/setLoading', false)
    if (status === 401) {
      router.replace({ path: '/login' })
    }
    console.log(status, config, 'error')
    // store.$dispatch('loading/loading', false)
    // for debug
    // message({
    //   message: error.message,
    //   type: 'error',
    //   duration: 3 * 1000
    // })
    return Promise.reject(error)
  }
)

export default service
