<template>
  <header class="head">
    <div class="container header">
      <div class="logo">
        <a href="javascript:;" @click="goHomeFun">
          <img src="@/assets/images/icons/logo.png" alt="logo" />
          <span class="logo-info">橱窗+</span>
        </a>
      </div>
      <ul class="nav">
        <!-- 餐厅灵感 -->
        <li>
          <a class="nav-info nav-info-ct" href="javascript:void(0)">
            <span>餐厅灵感</span>
            <div class="header_set ct">
              <div class="container" style="display: flex">
                <ul class="left_nav_slide">
                  <li
                    v-for="(item, index) in ctleftNavList"
                    :key="index"
                    @mouseover="leftNavMoveFun(index)"
                  >
                    <span class="left_nav_slide_title">{{ item.title }}</span>
                  </li>
                </ul>
                <div class="right_nav_slide">
                  <ul>
                    <li
                      v-for="(item, index) in ctrightNavList"
                      :key="index"
                      :class="index <= 3 ? 'db-mb24' : ''"
                    >
                      <img
                        src="@/assets/images/home/shebei.png"
                        :alt="item.title"
                      />
                      <div class="right_nav_slide_title">
                        <a href="javascript:;">{{ item.title }}</a>
                      </div>
                    </li>
                    <li
                      v-for="(k, kindex) in 4 - (ctrightNavList.length % 4)"
                      :key="kindex"
                      style="width: 274px; height: 205px"
                    />
                  </ul>
                </div>
              </div>
            </div>
          </a>
        </li>
        <!-- 餐厅灵感 -->
        <!-- 全部商品 -->
        <li>
          <a class="nav-info nav-info-shop" href="javascript:void(0)">
            <span @mouseover="getCateLevelCategoryFun">全部商品</span>
            <div class="header_set">
              <div class="container" style="display: flex">
                <ul class="left_nav_slide">
                  <li
                    v-for="(item, index) in shopleftNavList"
                    :key="index"
                    @mouseover="leftNavMoveFun(item)"
                  >
                    <span class="left_nav_slide_title">{{ item.name }}</span>
                  </li>
                </ul>
                <div class="right_nav_slide">
                  <ul>
                    <li
                      v-for="(item, index) in rightNavList"
                      :key="index"
                      :class="index <= 4 ? 'db-mb10' : ''"
                      @click="toAllGoods(item)"
                    >
                      <img
                        v-if="item.icon"
                        v-lazy="item.icon"
                        :alt="item.name"
                      />
                      <img
                        v-if="!item.icon"
                        src="@/assets/images/home/shebei.png"
                        :alt="item.name"
                      />
                      <span class="right_nav_slide_span">{{ item.name }}</span>
                    </li>
                    <li
                      v-for="(k, kindex) in 5 - (rightNavList.length % 5)"
                      :key="kindex"
                      style="width: 214px; height: 214px"
                    />
                  </ul>
                </div>
              </div>
            </div>
          </a>
        </li>
        <!-- 全部商品 -->
        <li>
          <a class="nav-info" href="javascript:void(0)"
            ><span>橱窗服务</span></a
          >
        </li>
        <li>
          <a class="nav-info" href="javascript:void(0)"
            ><span>最新活动</span></a
          >
        </li>
        <li>
          <a class="nav-info" href="javascript:void(0)"
            ><span>设计攻略</span></a
          >
        </li>
      </ul>
      <div class="right-fun">
        <div class="search">
          <img src="@/assets/images/icons/search.png" alt="search" />
          <a-input
            class="search-input"
            type="search"
            placeholder="电磁炉"
            @click="clickInputFun"
          />
        </div>
        <ul class="fun">
          <li>
            <img
              src="@/assets/images/icons/user.png"
              alt="user"
              @click="toFun('user')"
            />
          </li>
          <li>
            <a-badge :count="shoppingCart.length">
              <img
                src="@/assets/images/icons/shopping-car.png"
                alt="car"
                @click="toFun('car')"
              />
            </a-badge>
          </li>
          <li>
            <img
              src="@/assets/images/icons/heart.png"
              alt="heart"
              @click="toFun('heart')"
            />
          </li>
        </ul>
      </div>
    </div>

    <!-- 搜索栏 -->
    <div v-show="iShowSeach" class="header_set_search_box">
      <div class="header_set_search">
        <div class="kong">
          <img
            src="@/assets/images/icons/error.png"
            alt="close"
            @click="clickInputFun"
          />
          <a-input
            ref="searchInput"
            v-model:value="serachText"
            type="search"
            class="kong_search"
            placeholder="请输入"
            allow-clear
            @search="onSearch"
          />
          <img
            src="@/assets/images/icons/arrow-right-circle.png"
            alt="enter"
            @click="onSearch"
          />
        </div>
        <div class="search_bottom_box">
          <ul>
            <li class="search_bottom_box_title">
              搜索历史
              <a
                v-if="searchHistoryList.length != 0"
                href="javascript:;"
                @click="clearHisFun"
                >清空</a
              >
            </li>
            <li
              v-for="(item, index) in searchHistoryList"
              :key="index"
              class="db-mb24"
            >
              <a href="javascript:;" @click="clickSearch(item.title)">{{
                item.title
              }}</a>
            </li>
          </ul>
          <ul>
            <li class="search_bottom_box_title">热门搜索</li>
            <li class="hot-search-list">
              <div
                v-for="(item, index) in hotSearchList"
                :key="index"
                class="db-mb24"
              >
                <a href="javascript:;" @click="clickSearch(item)">{{ item }}</a>
              </div>
            </li>
          </ul>
          <div class="hot_classify_box">
            <span class="search_bottom_box_title">热门分类</span>
            <ul class="hot_classify">
              <li
                v-for="(item, index) in hotClassifyList"
                :key="index"
                :style="{
                  'margin-right': '6px',
                  'margin-bottom': '20px',
                }"
              >
                <img src="@/assets/images/home/shebei.png" alt="shebei" />
                <span>{{ item.title }}</span>
              </li>
            </ul>
          </div>
          <div class="hot_design_box">
            <span class="search_bottom_box_title">热门设计</span>
            <ul class="hot_design">
              <li
                v-for="(item, index) in hotDesignList"
                :key="index"
                :style="{
                  'margin-bottom': index + 1 <= 3 ? '16px' : '0',
                }"
              >
                <img src="@/assets/images/home/shebei.png" alt="shebei" />
                <span>{{ item.title }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="kong_Mask" @click="clickInputFun" />
    </div>
    <!-- <div class="container move-header" :class="iScroll ? 'moved' : ''">move-header</div> -->
  </header>
</template>

<script>
import {
  getCateLevelCategory,
  getNextCategory,
  getHotSearch,
  saveHotKey,
} from "@/api/goods";
import { mapActions, mapState } from "vuex";
// import { useRouter } from 'vue-router'

export default {
  name: "DbHeader",
  components: {},
  data() {
    return {
      iScroll: false,
      iSetting: false,
      iSetting_none: false, // 鼠标移入菜单 dom节点隐藏
      ctleftNavList: [
        { title: "中式厨房" },
        { title: "西式厨房" },
        { title: "星级酒店" },
        { title: "连锁餐饮" },
        { title: "新零售业" },
      ],
      ctrightNavList: [
        { img: "", title: "包子店" },
        { img: "", title: "饺子馆" },
        { img: "", title: "拉面店" },
        { img: "", title: "粥店" },
        { img: "", title: "中式快餐店" },
        { img: "", title: "饺子馆" },
      ],
      shopleftNavList: [], // 全部商品二级分类
      leftNavName: "",
      rightNavList: [],
      searchHistoryList: [],
      hotSearchList: [], // 热门搜索
      hotClassifyList: [
        // 热门分类
        { title: "吧台设备" },
        { title: "水处理设备" },
        { title: "白钢设备" },
        { title: "白钢设备" },
        { title: "白钢设备" },
      ],
      hotDesignList: [
        // 热门设计
        { title: "吧台设备" },
        { title: "水处理设备" },
      ],
      serachText: "",
      iShowSeach: false,
    };
  },

  computed: {
    ...mapState({
      shoppingCart: (state) => state.car.shoppingCart,
    }),
  },

  created() {
    this.getCateLevelCategoryFun();
    this.getHotSearchFun();
    this.searchHistoryList =
      JSON.parse(window.localStorage.getItem("mall_ser_his")) || [];
  },
  mounted() {
    window.addEventListener(
      "scroll",
      this.listenerFunction(this.changeScroll, 240)
    );
  },
  methods: {
    ...mapActions({
      checkUser: "user/getUserInfo",
    }),
    goHomeFun() {
      this.$router.push({ path: "/" });
    },
    clearHisFun() {
      // 清空历史记录
      window.localStorage.removeItem("mall_ser_his");
      this.searchHistoryList =
        JSON.parse(window.localStorage.getItem("mall_ser_his")) || [];
    },
    clickInputFun() {
      this.iShowSeach = !this.iShowSeach;
      if (this.iShowSeach) {
        this.$nextTick(() => {
          this.$refs.searchInput.focus();
        });
      } else {
        this.serachText = "";
      }
    },
    listenerFunction(fn, wait) {
      // 节流函数
      let prev = Date.now();
      return function () {
        const context = this;
        const args = arguments;
        const now = Date.now();
        if (now - prev >= wait) {
          fn.apply(context, args);
          prev = Date.now();
        }
      };
    },
    onSearch() {
      let list = [];
      if (window.localStorage.getItem("mall_ser_his"))
        list = JSON.parse(window.localStorage.getItem("mall_ser_his"));
      if (this.serachText) {
        list.unshift({ title: this.serachText });
        window.localStorage.setItem("mall_ser_his", JSON.stringify(list));
        this.saveHotKeyFun(this.serachText);
        this.searchHistoryList =
          JSON.parse(window.localStorage.getItem("mall_ser_his")).slice(0, 6) ||
          [];
        this.getHotSearchFun();
        this.$router.push({
          path: "/shop/allgoods",
          query: {
            type: "hasList",
            keyword: this.serachText,
          },
        });
      }
    },
    clickSearch(val) {
      this.$router.push({
        path: "/shop/allgoods",
        query: {
          type: "hasList",
          keyword: val,
        },
      });
    },
    changeScroll() {
      this.scroll =
        document.documentElement.scrollTop || document.body.scrollTop;
      if (this.scroll > 350) this.iScroll = true;
      else this.iScroll = false;
    },
    mouseoverFun(type) {
      const _this = this;
      let setimeover = null;
      if (type) {
        this.iSetting_none = true;
        if (setimeover) clearTimeout(setimeover);
        setimeover = setTimeout(() => {
          _this.iSetting = true;
        }, 100);
      }
    },
    mouseoutFun() {
      this.iSetting = false;
      const _this = this;
      let setime = null;
      if (setime) clearTimeout(setime);
      setime = setTimeout(() => {
        _this.iSetting_none = false;
      }, 100);
    },
    leftNavMoveFun(item) {
      // 鼠标移入分类
      this.getNextCategoryFun(item.catId);
      this.leftNavName = item.name;
    },
    toFun(val) {
      if (val === "user") this.$router.push({ path: "/user/userInfo" });
      if (val === "car") this.$router.push({ path: "/car" });
      if (val === "heart") this.$router.push({ path: "/user/collection" });
    },
    toAllGoods(item) {
      // this.$router.push({ name: 'AllGoods', params: { catId }})
      this.$router.push({
        path: "/shop/allgoods",
        query: {
          type: "hasID",
          catId: item.catId,
          parentCid: item.parentCid,
          parentName: this.leftNavName,
        },
      });
    },
    getNextCategoryFun(catId) {
      getNextCategory(catId).then((res) => {
        this.rightNavList = res.data;
      });
    },
    getCateLevelCategoryFun() {
      getCateLevelCategory().then((res) => {
        this.shopleftNavList = res.data;
        this.getNextCategoryFun(this.shopleftNavList[0].catId);
        this.leftNavName = this.shopleftNavList[0].name;
      });
    },
    getHotSearchFun() {
      getHotSearch().then((res) => {
        this.hotSearchList = res.data;
      });
    },
    saveHotKeyFun(word) {
      saveHotKey({ keyword: word }).then((res) => {
        console.log(res);
      });
    },
  },
};
</script>
<style lang='less' scoped>
.head {
  box-shadow: 0px 8px 16px rgba(162, 162, 162, 0.16);
}
.header {
  min-width: 1000px;
  height: 64px;
  color: #000;
  display: flex;

  .logo {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    img {
      width: 32px;
      height: 32px;
    }
    .logo-info {
      width: 42px;
      font-size: 14px;
      font-weight: 400;
      color: #3b6652;
      margin-left: 8px;
    }
  }
  .nav {
    margin-left: 70px;
    height: 100%;
    display: flex;
    align-items: center;

    & > li {
      padding: 20px 0px;
    }
    & > li:last-child {
      margin-right: 100px;
    }

    .nav-info {
      display: block;
      width: 108px;
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      padding: 24px 20px;
      &:hover {
        color: @color;
      }
    }
    .header_set {
      height: 0;
      visibility: hidden;
      z-index: -1;
    }
    .nav-info-ct:hover {
      .header_set {
        height: 482px;
        visibility: visible;
        z-index: 88;
      }
    }
    .nav-info-shop:hover {
      .header_set {
        visibility: visible;
        height: 536px;
        z-index: 99;
      }
    }
  }
  .right-fun {
    display: flex;
    align-items: center;

    .search {
      width: 308px;
      height: 40px;
      background-color: #f3f3f3;
      border-radius: 24px;
      margin-right: 35px;
      padding: 4px 20px;
      display: flex;
      align-items: center;

      img {
        width: 32px;
        height: 32px;
      }
      .search-input {
        width: 100%;
        border: none;
        background-color: #f3f3f3;
        box-shadow: none;
      }
    }
    .fun {
      display: flex;

      & > li {
        margin-right: 35px;
        cursor: pointer;
        border-radius: 50%;
        transition: all 0.2s linear;
        &:hover {
          background: #e5e5e5;
        }
      }
      & > li:last-child {
        margin-right: 0;
      }
    }
  }
}
.header_set_search_box {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  transition: all 0.3s ease;
  overflow: hidden;
  box-sizing: border-box;
  height: 100%;
}
// 搜索栏子菜单
.header_set_search {
  width: 100%;
  transition: all 0.3s ease;
  background-color: #fff;
  border-top: 1px solid #f2f2f2;
  box-shadow: 0px 8px 16px rgba(162, 162, 162, 0.16);
  overflow: hidden;
  box-sizing: border-box;
  height: 528px;
  padding: 0 15%;

  .kong {
    width: 100%;
    height: 119px;
    border-bottom: 1px solid #f2f2f2;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-bottom: 8px;

    & > img {
      width: 32px;
      height: 32px;
      cursor: pointer;
    }
    .kong_search {
      flex: 1;
      height: 38px;
      margin: 0 32px;
      border: 0;
      box-shadow: none;
      font-size: 26px;
    }
  }

  .search_bottom_box {
    display: flex;
    padding: 32px 0 0 24px;

    & > ul {
      width: 256px;
      margin-right: 75px;
    }
    & > ul > li {
      color: #666;
      font-weight: 400;
    }
    .hot-search-list {
      height: 260px;
      overflow-y: scroll;
      scrollbar-width: none;
      -ms-overflow-style: none;
      &::-webkit-scrollbar {
        display: none;
        width: 0px;
        height: 0px;
      }
    }

    .search_bottom_box_title {
      display: block;
      color: @main-color;
      font-size: 20px;
      font-weight: 400;
      margin-bottom: 24px;

      & > a {
        font-size: 12px;
        line-height: 32px;
        float: right;
      }
    }
    .hot_classify_box {
      // 热门分类
      width: 260px;
      margin-right: 75px;

      .hot_classify {
        height: 260px;
        display: flex;
        flex-wrap: wrap;
        text-align: center;
        overflow-y: scroll;
        scrollbar-width: none;
        -ms-overflow-style: none;
        &::-webkit-scrollbar {
          display: none;
          width: 0px;
          height: 0px;
        }

        & > li > img {
          display: block;
          width: 80px;
          height: 80px;
          margin-bottom: 8px;
        }
        & > li > span {
          width: 80px;
          color: @main-color;
          font-size: 12px;
          font-weight: 400;
        }
      }
    }

    .hot_design_box {
      // 热门设计
      width: 256px;

      .hot_design {
        text-align: center;

        & > li > img {
          display: block;
          width: 160px;
          height: 93px;
        }
        & > li > span {
          display: block;
          width: 160px;
          height: 27px;

          background-color: #d7e0dc;
          color: @main-color;
          font-size: 12px;
          line-height: 27px;
          font-weight: 400;
        }
      }
    }
  }
}
.kong_Mask {
  // 阴影外层
  width: 100%;
  height: calc(100% - 528px);
  background: #333333;
  opacity: 0.5;
}

// 选中种类显示
.header_set {
  width: 100%;
  position: absolute;
  top: 64px;
  left: 0;
  right: 0;
  z-index: 99;
  // transition: all .3s ease;
  background-color: #fff;
  border-top: 1px solid #f2f2f2;
  box-shadow: 0px 8px 16px rgba(162, 162, 162, 0.16);
  overflow: hidden;
  box-sizing: border-box;

  .left_nav_slide {
    width: 132px;
    height: 100%;
    border-right: 1px solid #f2f2f2;
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    & > li {
      height: 20px;
      margin-bottom: 32px;
    }
    & > li:hover .left_nav_slide_title {
      color: @color;
      border-bottom: 1px solid @color;
    }

    & > li:first-child {
      margin-top: 24px;
    }
    & > li:last-child {
      margin-bottom: 24px;
    }

    .left_nav_slide_title {
      color: #666666;
      font-weight: 400;
    }
  }

  .right_nav_slide {
    width: 100%;
    padding: 24px 24px 0;

    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    ul > li {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-content: center;
      text-align: center;
      color: @main-color;
    }
    .right_nav_slide_span {
      display: inline-block;
      margin: auto;
      border-bottom: 1px solid #fff;
    }
    ul > li:hover .right_nav_slide_span {
      color: @color;
      border-bottom: 1px solid @color;
    }

    ul > li img {
      width: 214px;
      height: 214px;
      margin-bottom: 8px;
    }
  }
}
.ct {
  .right_nav_slide {
    .right_nav_slide_title {
      width: 100%;
      height: 48px;
      text-align: center;
      line-height: 48px;
      background-color: #d7e0dc;

      & > a:hover {
        border-bottom: 1px solid;
      }
    }

    ul > li img {
      width: 274px;
      height: 157px;
      margin-bottom: 0px;
    }
  }
}
</style>
