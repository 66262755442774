<template>
  <div class="main">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "DbMain",
  components: {},
  data() {
    return {};
  },

  computed: {},

  methods: {},
};
</script>
<style lang='less' scoped>
.main {
  width: 100%;
  min-height: 100vh;
}
</style>
