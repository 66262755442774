import request from '@/utils/request'

// 二级分类接口
export function getCateLevelCategory() {
  return request({
    url: '/api/product/category/getCateLevelCategory/1',
    method: 'get'
  })
}
// 根据二级分类查找三级分类接口
export function getNextCategory(catId) {
  return request({
    url: `/api/product/category/getNextCategory/${catId}`,
    method: 'get'
  })
}

// 商品搜索
export function spuInfoList(params) {
  return request({
    url: `/api/search/search/spuInfoList`,
    method: 'get',
    params
  })
}

// 获取热门搜索
export function getHotSearch() {
  return request({
    url: `/api/search/search/getHotSearch`,
    method: 'get'
  })
}

// 保存热门搜索
export function saveHotKey(data) {
  return request({
    url: `/api/search/save/saveHotKey`,
    method: 'post',
    data
  })
}

// 商品详情
export function skuinfoDetail(skuId) {
  return request({
    url: `/api/product/skuinfo/item/${skuId}`,
    method: 'get'
  })
}

// 商品详情 相关配件/耗材/组合推荐 (1：相关配件，2：相关耗材，3：组合推荐)
export function getAboutSku(params) {
  return request({
    url: `/api/product/skusku/getAboutSku`,
    method: 'get',
    params
  })
}

// 商品详情 相关保障/资料 (1:售后保障，2：相关资料)
export function getSalesBySpuId(params) {
  return request({
    url: `/api/product/salesspu/getSalesBySpuId`,
    method: 'get',
    params
  })
}

/**
 * 收藏商品
 * skuId=？
*/
export function collectSku(params) {
  return request({
    url: `/api/member/web/collectSku`,
    method: 'post',
    params
  })
}

/**
 * 获取该用户收藏的商品
*/
export function getCollectSkus() {
  return request({
    url: `/api/member/web/getCollectSkus`,
    method: 'get'
  })
}
/**
 * 判断该商品是否被收藏
 * skuId=？
*/
export function isCollect(params) {
  return request({
    url: `/api/member/web/isCollect`,
    method: 'get',
    params
  })
}
/**
 * 添加足迹
 * skuId=？
*/
export function saveFooterPrint(params) {
  return request({
    url: `/api/member/web/saveFooterPrint`,
    method: 'post',
    params
  })
}
/**
 * 获取该用户足迹
 *  weekIndex 1:周一 2:周二 。。。。。7：周日
*/
export function getFooterPrintByTime(params) {
  return request({
    url: `/api/member/web/getFooterPrintByTime`,
    method: 'get',
    params
  })
}
