<template>
  <footer>
    <div class="footer">
      <div class="container">
        <div class="footer_info">
          <ul>
            <li class="footer_info_title">关于厨窗</li>
            <li><a href="javascript:;">厨房设计</a></li>
            <li><a href="javascript:;">厨窗商城</a></li>
            <li><a href="javascript:;">送货服务</a></li>
            <li><a href="javascript:;">安装服务</a></li>
            <li><a href="javascript:;">售后服务</a></li>
            <li><a href="javascript:;">对公业务</a></li>
          </ul>
          <ul>
            <li class="footer_info_title">厨窗政策</li>
            <li><a href="javaScript:;">会员政策</a></li>
            <li><a href="javascript:;">使用协议</a></li>
            <li><a href="javascript:;">隐私政策</a></li>
            <li><a href="javascript:;">广告协议</a></li>
          </ul>
          <ul>
            <li class="footer_info_title">常用链接</li>
            <li><a href="javascript:;">https://www.ikea.cn</a></li>
            <li><a href="javascript:;">https://www.ikea.cn/cn/zh/?utm_</a></li>
            <li><a href="javascript:;">https://www.ikea.cn/cn</a></li>
            <li><a href="javascript:;">https://www.ike</a></li>
            <li><a href="javascript:;">https://www.ikea.cn/c</a></li>
            <li><a href="javascript:;">https://www.ikea.cn/c</a></li>
          </ul>
        </div>
        <div class="footer_copyright">
          <div class="footer_copyright_info">
            北京东邦御厨科技股份有限公司 1999-2021
          </div>
          <div class="footer_copyright_info">
            <span>京公网安备 31011202001219号</span>
            <span
              ><a
                onclick="window.open('https://beian.miit.gov.cn')"
                class="icp-a"
                >京ICP备12031205号-9</a
              ></span
            >
            <span>ICP经营许可证:京B2-20160056号</span>
            <span>|</span>
            <span>隐私政策</span>
            <span>|</span>
            <span>使用协议</span>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
// export default {};
export default {
  name: "DbFooter",
  components: {},
  data() {
    return {};
  },
};
</script>
<style lang='less' scoped>
.footer {
  width: 100%;
  height: 518px;
  background-color: #333333;

  .footer_info {
    width: 100%;
    height: 367px;
    border-bottom: 1px solid #f8f8f8;
    padding-top: 64px;
    color: #fff;
    display: flex;
    justify-content: space-between;

    & > ul > li > a {
      color: #999999;
      margin-bottom: 16px;
      display: block;
    }

    .footer_info_title {
      font-size: 16px;
      font-weight: 400;
      color: #dbdbdb;
      margin-bottom: 24px;
    }
  }
  .footer_copyright {
    width: 100%;
    height: 150px;
    text-align: center;
    padding-top: 48px;
    box-sizing: border-box;

    .footer_copyright_info {
      color: #a2a2a2;
      font-weight: 400;
      margin-bottom: 16px;
      .icp-a {
        color: #a2a2a2;
      }
      .icp-a:hover {
        color: blue;
      }
    }
  }
}
</style>
