import request from '@/utils/request'

// 获取用户信息
export function verify() {
  return request({
    url: '/api/auth/verify',
    method: 'get'
  })
}

/**
 * 添加收获地址
 * memberId, name, phone, province, city, region, detailAddress, postCode, defaultStatus(1--默认，2--非默认)
*/
export function saveAdderss(data) {
  return request({
    url: `/api/member/memberreceiveaddress/save`,
    method: 'post',
    data
  })
}
/**
 * 修改收获地址
 * memberId, name, phone, province, city, region, detailAddress, postCode, defaultStatus(1--默认，2--非默认)
*/
export function updateAdderss(data) {
  return request({
    url: `/api/member/memberreceiveaddress/update`,
    method: 'post',
    data
  })
}
/**
 * 删除收获地址
*/
export function deleteAdderss(data) {
  return request({
    url: `/api/member/memberreceiveaddress/delete`,
    method: 'post',
    data
  })
}
/**
 * 获取当前用户的收货地址列表
*/
export function listAll(memberId) {
  return request({
    url: `/api/member/memberreceiveaddress/listAll/${memberId}`,
    method: 'get'
  })
}

/**
 * 账户中心
*/
export function getLoginMember() {
  return request({
    url: `/api/member/web/getLoginMember`,
    method: 'get'
  })
}
/**
 * 修改账户信息
*/
export function updateMember(params) {
  return request({
    url: `/api/member/web/updateMember`,
    method: 'post',
    params
  })
}

export function gdmap(params) {
  return request({
    url: `/v3/config/district`,
    method: 'get',
    params
  })
}

