import request from '@/utils/request'

/**
 * 添加购物车
 * data = {skuId, num}
*/
export function addToCart(params) {
  return request({
    url: '/api/cart/addToCart',
    method: 'post',
    params
  })
}

// 获取购物车列表
export function getCartList() {
  return request({
    url: '/api/cart/getCartList',
    method: 'get'
  })
}
/**
 * 修改购物车商品数量
 * data = {skuId, num}
*/
export function changeItemCount(params) {
  return request({
    url: '/api/cart/changeItemCount',
    method: 'post',
    params
  })
}

/**
 * 删除购物车商品
 * data = {skuId, num}
*/
export function deleteItem(params) {
  return request({
    url: '/api/cart/deleteItem',
    method: 'post',
    params
  })
}

/**
 * 选中/取消选商品接口
 * data = {skuId check （check 1选中，2取消选中）}
*/
export function checkItem(params) {
  return request({
    url: '/api/cart/checkItem',
    method: 'post',
    params
  })
}

/**
 * 选中/取消选全部商品接口
 * data = {skuIds check （check 1选中，2取消选中）}
*/
export function checkAllItems(params) {
  return request({
    url: '/api/cart/checkAllItems',
    method: 'post',
    params
  })
}

